"use client";

import { Flex, Text } from "@chakra-ui/react";

import BellLargeIcon from "@/assets/svg/bell-large.svg";
import { h_400_14_135_28, h_700_14_16 } from "@/styles/fontStyles";
import { ButtonDefault } from "@/components/ui/buttons/DefaultBtn";
import Link from "next/link";

export const NoNotifications = ({
  isUnread,
  getNotifications,
  getUnreadNotifications,
}) => {
  const refreshHandler = () => {
    !isUnread && getNotifications(), isUnread && getUnreadNotifications();
  };

  return (
    <Flex align={"center"} justify={"center"} h={"273px"} p={"20px"}>
      <Flex flexDir={"column"} align={"center"} gap={"12px"}>
        <BellLargeIcon />
        <Text {...h_700_14_16}>No notifications yet</Text>
        <Link href={"settings/account"} alt={"Settings"}>
          <Text {...h_400_14_135_28} textDecoration={"underline"}>
            Adjust your notification settings
          </Text>
        </Link>
        <ButtonDefault
          title={"Refresh"}
          color={"black"}
          customStyles={{
            borderRadius: "4px",
            height: "34px",
            border: "1px",
            ml: { base: "none", lg: "8px" },

            width: "100%",
            maxWidth: { base: "100%", lg: "102px" },
            px: { base: 0, lg: "12px, 24px, 12px, 24px" },
          }}
          onClick={refreshHandler}
        />
      </Flex>
    </Flex>
  );
};
