const getUserIsVerify = () => (state) => state.auth.userIsVerify;
const getUserSignUpEmail = () => (state) => state.auth.userSignUpEmail;
const getUserSignUpInfo = () => (state) => state.auth.userSignUpInfo;
const get2FAError = () => (state) => state.auth.isNeed2fA;
const getGoogle2FAError = () => (state) => state.auth.isGoogle2fA;
const getLinkedIn2FAError = () => (state) => state.auth.isLinkedIn2fA;

export {
  getUserIsVerify,
  get2FAError,
  getUserSignUpEmail,
  getUserSignUpInfo,
  getGoogle2FAError,
  getLinkedIn2FAError,
};
