const getUserProfileInfo = () => (state) => state.profile.profile;
const getCountPage = () => (state) => state.profile.countPage;
const getIsConnected = () => (state) => state.profile.isConnected;
const getAccreditation = () => (state) => state.profile.accreditationQuestions;
const getNotificationsSettings = () => (state) =>
  state.profile.notificationsSettings;
const getUserNotificationsSettings = () => (state) =>
  state.profile.userNotificationsSettings;
const getAccreditationForm = () => (state) => state.profile.accreditationForm;

export {
  getUserProfileInfo,
  getIsConnected,
  getAccreditation,
  getNotificationsSettings,
  getUserNotificationsSettings,
  getAccreditationForm,
  getCountPage,
};
