import {
  useLazyGetNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
} from "@/services/notifications.service";
import { getUserIsVerify } from "@/store/auth/auth.selectors";
import {
  getNotificationsData,
  getUnreadNotificationsData,
} from "@/store/notifications/notifications.selectors";
import { Flex } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BlueTextBoxArrow } from "../ui/box/BlueTextBoxArrow";
import { NoNotifications } from "./NoNotifications";
import NotificationCard from "./NotificationCard";
import NotificationsHeader from "./NotificationsHeader";

const NotificationsList = ({ isHeaderMenu = false }) => {
  const pathname = usePathname();
  const [getNotifications] = useLazyGetNotificationsQuery();
  const [getUnreadNotifications] = useLazyGetUnreadNotificationsQuery();

  const allNotifications = useSelector(getNotificationsData());
  const notificationsUnread = useSelector(getUnreadNotificationsData());
  const userIsVerify = useSelector(getUserIsVerify());
  const [isUnread, setIsUnread] = useState(false);

  const allNotificationHandler = () => {
    setIsUnread(false);
  };

  const unreadNotificationHandler = () => {
    setIsUnread(true);
  };

  useEffect(() => {
    const profile = setTimeout(() => {
      userIsVerify &&
        (!isUnread && getNotifications(), isUnread && getUnreadNotifications());
    }, 10);

    return () => clearTimeout(profile);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsVerify, isUnread]);

  return (
    <Flex
      flexDir={"column"}
      p={isHeaderMenu ? "0" : "20px"}
      rowGap={"12px"}
      w={"100%"}
      maxW={"507px"}
      bg={"white"}
      borderRadius={"4px"}
      mx={{ base: "auto" }}
      mb={isHeaderMenu ? "0" : "80px"}
    >
      {pathname === "/notifications" ? (
        <BlueTextBoxArrow
          {...{
            title: "Notifications",
            boxStyles: {
              ml: "-20px",
            },
          }}
        />
      ) : null}
      <NotificationsHeader
        {...{
          allNotificationHandler,
          isUnread,
          unreadNotificationHandler,
          isHeaderMenu,
        }}
      />
      {!isUnread ? (
        allNotifications?.length ? (
          <>
            {allNotifications?.map((item) => (
              <NotificationCard key={item?.id} {...{ itemEl: item }} />
            ))}
          </>
        ) : (
          <NoNotifications
            {...{ isUnread, getNotifications, getUnreadNotifications }}
          />
        )
      ) : notificationsUnread?.length ? (
        <>
          {notificationsUnread?.map((item) => (
            <NotificationCard key={item?.id} {...{ itemEl: item }} />
          ))}
        </>
      ) : (
        <NoNotifications
          {...{ isUnread, getNotifications, getUnreadNotifications }}
        />
      )}
    </Flex>
  );
};

export default memo(NotificationsList);
