export const menuListInvest = [
  { id: 1, title: 'Invest', route: '/invest' },
  { id: 2, title: 'View Funds', route: '/view_funds' },
  { id: 3, title: 'Portfolio Overview', route: '/portfolio' },
  { id: 4, title: 'Ongoing Investments', route: '/investments' },
  { id: 5, title: 'Withdrawal', route: '/withdrawal' },
];

export const menuListResearch = ({ isDev }) => {
  return isDev
    ? [
        { id: 1, title: 'Companies', route: '/companies' },
        { id: 2, title: 'Investors', route: '/investors' },
        { id: 3, title: 'People', route: '/people' },
      ]
    : [
        { id: 1, title: 'Companies', route: '/companies' },
        { id: 2, title: 'Investors', route: '/investors' },
        { id: 3, title: 'People', route: '/people' },
      ];
};

export const menuListProfile = [
  { id: 1, title: 'Account Settings', route: '/settings/account' },
  // { id: 2, title: 'Documents', route: '/documents' },
  // { id: 3, title: 'Sitemap', route: '/sitemap' },
  { id: 4, title: 'Log Out', route: '/' },
];
export const mobileListMenu = ({ isDev }) => {
  const isQa = process.env.NEXT_ENVIRONMENT === 'staging';

  return isDev || isQa
    ? [
        // {
        //   id: 2,
        //   title: "Invest",
        //   route: "/invest",
        //   subitems: [
        //     { title: "Invest", route: "/invest/view-funds" },
        //     { title: "View Funds", route: "/invest/portfolio-overview" },
        //     { title: "Portfolio Overview", route: "/invest/ongoing-investments" },
        //     { title: "Ongoing Investments", route: "/invest/withdrawal" },
        //     { title: "Withdrawal", route: "/invest/withdrawal" },
        //   ],
        // },
        {
          id: 3,
          title: 'Research',
          route: '/',
          subitems: [
            { title: 'Companies', route: '/companies' },
            { title: 'Investors', route: '/investors' },
            { title: 'People', route: '/people' },
          ],
        },
        {
          id: 4,
          title: 'aVenture',
          route: '/',
          subitems: [
            // { title: "About", route: "/" },
            { title: 'Contact', route: '/' },
          ],
        },
        {
          id: 5,
          title: 'Account',
          route: '/settings/account',
          subitems: [
            { title: 'Settings', route: '/settings/account' },
            { title: 'Documents', route: '/documents' },
            { title: 'Sitemap', route: '/sitemap' },
          ],
        },
      ]
    : [
        // {
        //   id: 2,
        //   title: "Invest",
        //   route: "/invest",
        //   subitems: [
        //     { title: "Invest", route: "/invest/view-funds" },
        //     { title: "View Funds", route: "/invest/portfolio-overview" },
        //     { title: "Portfolio Overview", route: "/invest/ongoing-investments" },
        //     { title: "Ongoing Investments", route: "/invest/withdrawal" },
        //     { title: "Withdrawal", route: "/invest/withdrawal" },
        //   ],
        // },
        {
          id: 3,
          title: 'Research',
          route: '/',
          subitems: [
            { title: 'Companies', route: '/companies' },
            { title: 'Investors', route: '/investors' },
            { title: 'People', route: '/people' },
          ],
        },
        // {
        //   id: 4,
        //   title: 'aVenture',
        //   route: '/',
        //   subitems: [
        //     // { title: "About", route: "/" },
        //     { title: 'Contact', route: '/' },
        //   ],
        // },
        {
          id: 5,
          title: 'Account',
          route: '/settings/account',
          subitems: [
            { title: 'Settings', route: '/settings/account' },
            // { title: 'Documents', route: '/documents' },
            // { title: 'Sitemap', route: '/sitemap' },
          ],
        },
      ];
};
