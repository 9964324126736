import { Flex } from "@chakra-ui/react";
import { CustomElement } from "../ui/box/CustomElement";
import DotMenu from "./DotMenu";
import { memo } from "react";
import { useMarkReadAllNotificationMutation } from "@/services/notifications.service";

const NotificationsHeader = ({
  allNotificationHandler,
  unreadNotificationHandler,
  isHeaderMenu,
  isUnread,
}) => {
  const [markReadAllNotification] = useMarkReadAllNotificationMutation();

  const list = [
    {
      id: 1,
      title: "Mark all as read",
      handler: () => markReadAllNotification(),
    },
    {
      id: 2,
      title: "Notification Settings",
      path: "/settings/account",
    },
    { id: 3, title: "Open Notifications", path: "/notifications" },
  ];

  return (
    <Flex alignItems={"center"} justifyContent={"space-between"}>
      <Flex alignItems={"center"} columnGap={"12px"}>
        <CustomElement
          {...{
            variant: !isUnread ? "blueBtn" : "outlineBtn",
            title: "All",
            customStyle: {
              h: "36px",
              borderColor: !isUnread ? "transparent" : "mainBlue",
              color: !isUnread ? "white" : "mainBlue",
              _hover: {
                bg: isUnread ? "transparent" : "blue",
                color: isUnread ? "grey" : "white",
                borderColor: isUnread ? "grey" : "transparent",
              },
            },
          }}
          handler={allNotificationHandler}
        />
        <CustomElement
          {...{
            variant: isUnread ? "blueBtn" : "outlineBtn",
            title: "Unread",
            customStyle: {
              h: "36px",
              borderColor: isUnread ? "transparent" : "mainBlue",
              color: isUnread ? "white" : "mainBlue",
              _hover: {
                bg: !isUnread ? "transparent" : "blue",
                color: !isUnread ? "grey" : "white",
                borderColor: !isUnread ? "grey" : "transparent",
              },
            },
          }}
          handler={unreadNotificationHandler}
        />
      </Flex>
      <DotMenu {...{ list: isHeaderMenu ? list : list?.splice(0, 2) }} />
    </Flex>
  );
};

export default memo(NotificationsHeader);
