"use client";

import { r_400_54_normal } from '@/styles/fontStyles';
import { Heading } from '@chakra-ui/react';

export const RoslindaleText = ({
  children,
  customStyle,
  headingType = 'p',
}) => {
  return (
    <Heading as={headingType} {...r_400_54_normal} {...customStyle}>
      {children}
    </Heading>
  );
};
