'use client';

import { h_700_14_100_28 } from '@/styles/fontStyles';
import { Box, Button, Text } from '@chakra-ui/react';
import { memo } from 'react';

const ButtonDefault = memo(function ButtonDefault({
  isLoading,
  children,
  title,
  leftIcon,
  rightIcon,
  customStyles,
  onClick,
  type,
  btnRef,
  error = false,
  id = false,
  backgroundColor = 'white',
  borderColor = 'mainBlue',
  color = 'white',
  hoverBgColor,
  hoverColor,
  hoverBorderColor,
  textStyles,
}) {
  return (
    <Button
      position={'relative'}
      overflow={'hidden'}
      borderRadius={'8px'}
      border={'1px'}
      borderColor={borderColor}
      color={color}
      padding={'12px 18px 8px 18px'}
      backgroundColor={backgroundColor}
      _hover={{
        color: hoverColor,
        borderColor: hoverBorderColor,
        backgroundColor: hoverBgColor,
      }}
      sx={{
        '.chakra-button__icon': { m: '0px' },
        '.chakra-text': { pt: '3px' },
      }}
      leftIcon={leftIcon || undefined}
      rightIcon={rightIcon || undefined}
      onClick={onClick}
      type={type}
      transition={'background ease .5s'}
      isDisabled={error}
      ref={btnRef}
      {...{ isLoading }}
      {...customStyles}
      {...(id && { id: id })}
    >
      {children ? (
        children
      ) : (
        <Text {...h_700_14_100_28} {...textStyles}>
          {title}
        </Text>
      )}
    </Button>
  );
});

export { ButtonDefault };
