const getAllViews = () => (state) => state.newViews.allViews;
const getCurrentView = () => (state) => state.newViews.currentNewView;
const getViewParams = () => (state) => state.newViews.viewParams;
const getArrayFilters = () => (state) => state.newViews.arrayFilters;
const getSavedViewParams = () => (state) => state.newViews.savedParams;

const newViewSelectors = {
  getAllViews,
  getCurrentView,
  getViewParams,
  getArrayFilters,
  getSavedViewParams,
};
export default newViewSelectors;
