"use client";

import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import FooterText from "./footerText/FooterText";
// import Logo from "../../../public/images/aventure-logo.svg";
import Logo from "@/assets/svg/alpha-footer-logo.svg";
import FooterSocials from "./footerSocials/FooterSocials";
import FooterSections from "./footerSections/FooterSections";
import MembersLogo from "../../../public/images/members-logo1.svg";
import TechLogo from "@/assets/svg/tech-footer.svg";
import { MainWrapper } from "../ui/box/MainWrapper";
import Link from "next/link";
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <MainWrapper
      customStyleOuter={{
        bgColor: "mainBlue",
        p: {
          base: "24px 24px 46px 24px",
          md: "24px 40px 40px 40px",
          desk: "76px 40px 132px 40px",
        },
      }}
      customStyleInner={{ maxW: "100%" }}
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={{ base: "30px", lg: "40px" }}
        gap={"53px"}
      >
        <Link href={"/"} alt={"Home"}>
          <Logo />
        </Link>
        <Box
          border={"1px solid white"}
          p={"10px"}
          display={{ base: "block", md: "none", lg: "block" }}
          borderRadius={"4px"}
        >
          <Text color={"white"} fontSize={"12px"}>
            aVenture is in Alpha: aVenture recently launched early public access
            to our research product. It&apos;s intended to illustrate
            capabilities and gather feedback from users. While in Alpha, you
            should expect the research data to be limited and may not yet meet
            our exacting standards. We&apos;ve made the decision to temporarily
            present this information to showcase the product&apos;s potential,
            but you should not yet rely upon it for your investment decisions.
          </Text>
        </Box>

        <FooterSocials />
      </Flex>
      <Box
        border={"1px solid white"}
        p={"10px"}
        display={{ base: "none", md: "block", lg: "none" }}
        mb={"40px"}
        borderRadius={"4px"}
      >
        <Text color={"white"} fontSize={"12px"}>
          aVenture is in Alpha: aVenture recently launched early public access
          to our research product. It&apos;s intended to illustrate capabilities
          and gather feedback from users. While in Alpha, you should expect the
          research data to be limited and may not yet meet our exacting
          standards. We&apos;ve made the decision to temporarily present this
          information to showcase the product&apos;s potential, but you should
          not yet rely upon it for your investment decisions.
        </Text>
      </Box>
      <FooterSections />
      <Flex alignItems={"center"} my={{ base: "30px", md: "40px" }}>
        <Text color={"white"} mr={"8px"}>
          Member
        </Text>
        <Flex flexDirection={"column"} alignItems={"center"} cursor={"pointer"}>
          <Tooltip
            label={"National Venture Capital Association"}
            placement="top"
            bg="gray.300"
            color="black"
          >
            <a
              href="https://nvca.org"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <MembersLogo />
            </a>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex alignItems={"center"} mb={{ base: "30px", md: "40px" }}>
        <Text color={"white"} mr={"8px"}>
          Backed by
        </Text>
        <Flex flexDirection={"column"} alignItems={"center"} cursor={"pointer"}>
          <Tooltip
            label={"techstars"}
            placement="top"
            bg="gray.300"
            color="black"
          >
            <a
              href="https://www.techstars.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <TechLogo />
            </a>
          </Tooltip>
        </Flex>
      </Flex>
      <Text color={"white"}>
        © aVenture Investment Company, {currentYear}. All rights reserved.
      </Text>
      <Text color={"white"}> 44 Tehama St, San Francisco, CA 94105</Text>
      <Link
        href={"/privacy"}
        target="blank"
        rel={"noopener noreferrer nofollow"}
        cursor="pointer"
      >
        <Text color={"white"} textDecoration={"underline"}>
          Privacy Policy
        </Text>
      </Link>

      <div
        style={{
          width: "100%",
          height: "2px",
          backgroundColor: "white",
          margin: "48px 0",
        }}
      />
      <FooterText />
    </MainWrapper>
  );
}

export default Footer;
