import newViewSelectors from "@/store/newView/newView.selectors";
import { useSelector } from "react-redux";

const useGetCurrentView = () => {
  const currentView = useSelector(newViewSelectors.getCurrentView());
  const viewParams = useSelector(newViewSelectors.getViewParams());
  const savedParams = useSelector(newViewSelectors.getSavedViewParams());
  const currentSavedParams = savedParams?.[currentView?.id];
  const currentParams = viewParams?.[currentView?.id];
  const arrayFilters = useSelector(newViewSelectors.getArrayFilters());
  const currentArrayFilters = arrayFilters?.[currentView?.id];

  return {
    currentView,
    viewParams,
    savedParams,
    currentSavedParams,
    currentParams,
    currentArrayFilters,
  };
};
export default useGetCurrentView;
