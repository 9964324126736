"use client";

import Fb from "@/assets/svg/fb-footer.svg";
import Instagram from "@/assets/svg/insta-footer.svg";
import X_SVG from "@/assets/svg/x-blanc.svg";
import { Box, Flex } from "@chakra-ui/react";
import LinkedIn from "../../../../public/images/linkedIn.svg";

import Link from "next/link";

const socialMediaLinks = [
  {
    icon: <LinkedIn />,
    href: "https://linkedin.com/company/aventure-vc/ ",
    target: "_blank",
    rel: "noopener noreferrer nofollow",
  },
  {
    icon: <Fb />,
    href: "https://www.facebook.com/aVentureVC/",
    target: "_blank",
    rel: "noopener noreferrer nofollow",
  },
  {
    icon: <X_SVG />,
    href: "https://twitter.com/aVentureVC",
    target: "_blank",
    rel: "noopener noreferrer nofollow",
  },
  {
    icon: <Instagram />,
    href: "https://instagram.com/aVentureVC",
    target: "_blank",
    rel: "noopener noreferrer nofollow",
  },
];

function FooterSocials() {
  const iconStyle = {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    margin: "5px",
    border: "2px solid white",
    cursor: "pointer",
  };

  return (
    <Flex>
      {socialMediaLinks.map((link, index) => (
        <Box
          key={`${link?.href}-${index}`}
          style={iconStyle}
          cursor={"pointer"}
        >
          <Link href={link.href} passHref target={link?.target} rel={link?.rel}>
            {link.icon}
          </Link>
        </Box>
      ))}
    </Flex>
  );
}

export default FooterSocials;
