import DotIcon from "@/assets/svg/dot.svg";
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import Link from "next/link";
import { Fragment } from "react";

const DotMenu = ({ list }) => {
  return (
    <Menu is>
      <MenuButton onClick={(event) => event.stopPropagation()}>
        <Flex
          alignItems={"center"}
          columnGap={"2px"}
          h={"16px"}
          cursor={"pointer"}
        >
          {Array.from(Array(3).keys()).map((item, index) => (
            <Fragment key={index}>
              <DotIcon />
            </Fragment>
          ))}
        </Flex>
      </MenuButton>
      <MenuList
        bg={"dashboardBackground"}
        border={"none"}
        zIndex={1100}
        overflowY={"auto"}
        overflowX={"hidden"}
        minW={"180px"}
        boxShadow={"0px 0px 20px 5px rgba(128, 128, 128, 0.25)"}
      >
        {list?.map((item, index) => (
          <MenuItem
            key={`${item?.title}-${index}`}
            _hover={{ bg: "none" }}
            p={"0px"}
          >
            <Link
              href={item?.path || {}}
              alt={item?.title}
              onClick={item?.handler}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text
                w={"100%"}
                py={"8px"}
                px={"20px"}
                fontSize={"14px"}
                color={"black"}
                fontWeight={"700"}
                lineHeight={"1.2"}
                letterSpacing={"0.28px"}
                _hover={{ bg: "mainBlue", color: "white" }}
              >
                {item?.title}
              </Text>
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DotMenu;
