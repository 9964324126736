import {
  useCompaniesByListMutation,
  useCompaniesListMutation,
} from "@/services/newCompaniesList.service";
import newListSelectors from "@/store/newLists/newLists.slectors";
import { useSelector } from "react-redux";
import { useActions } from "./useActions";

const useCompaniesListRequest = () => {
  const { setIsLoadingCompanies } = useActions();
  const currentList = useSelector(newListSelectors.getCurrentList());

  const [companiesList] = useCompaniesListMutation();
  const [companiesByList] = useCompaniesByListMutation();

  const getCompanies = (params, listId) => {
    const isAllCompanies = (listId || currentList?.id) === "all companies";
    setIsLoadingCompanies(true);
    isAllCompanies
      ? companiesList(params).then(() => setIsLoadingCompanies(false))
      : companiesByList({ params, key: listId || currentList?.id }).then(() =>
          setIsLoadingCompanies(false)
        );
  };

  return { getCompanies };
};

export default useCompaniesListRequest;
