"use client";

import { Box, Flex, Text } from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";

import { useActions } from "@/hooks/useActions";
import contentSelectors from "@/store/content/content.selectors";
import { h_700_16_16 } from "@/styles/fontStyles";
import { Dropdown } from "../../dropdown/Dropdown";

export const HeaderNavigation = ({ buttonsArray = [] }) => {
  const router = useRouter();
  const { setCurrentNavLink } = useActions();
  const currentNavLink = useSelector(contentSelectors.getCurrentNavLink());

  const menuList = [
    { id: 1, title: "Research", route: "/research" },
    { id: 2, title: "Investing", route: "/investing" },
    { id: 3, title: "Fund Management", route: "/management" },
  ];

  return (
    <Flex align={"center"} gap={"17px"} h={"full"}>
      <Dropdown
        menuList={menuList}
        menuListBgColor={"mainBlue"}
        titleButton={"Services"}
        isSubMenu={true}
      />
      {buttonsArray?.map((item, index) => {
        return (
          <Box
            as={"button"}
            key={`${item?.title}-${index}`}
            bg={"none"}
            px={"0px"}
            h={"100%"}
            borderBottom={"2px"}
            borderColor={currentNavLink === item?.id ? "red" : "transparent"}
            onClick={() => {
              setCurrentNavLink(item?.id);
              router.push(item?.route);
            }}
          >
            <Text {...h_700_16_16} color={"white"}>
              {item?.title}
            </Text>
          </Box>
        );
      })}
    </Flex>
  );
};
