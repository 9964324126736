import { Flex, Text } from "@chakra-ui/react";
import footerText from "@/assets/data/footer/footerText.json";
import { h_400_14_135_56 } from "@/styles/fontStyles";

function FooterText() {
  return (
    <>
      {footerText && (
        <Flex
          flexDir={{ base: "column", md: "column", lg: "row" }}
          gap={"30px"}
          {...h_400_14_135_56}
          color={"white"}
        >
          <Flex flexDir={"column"} gap={"30px"}>
            <Text dangerouslySetInnerHTML={{ __html: footerText[0] }}></Text>
            <Text dangerouslySetInnerHTML={{ __html: footerText[1] }}></Text>
            <Text dangerouslySetInnerHTML={{ __html: footerText[2] }}></Text>
            <Text dangerouslySetInnerHTML={{ __html: footerText[3] }}></Text>
            <Text dangerouslySetInnerHTML={{ __html: footerText[4] }}></Text>
          </Flex>
          <Flex flexDir={"column"} gap={"30px"}>
            <Text dangerouslySetInnerHTML={{ __html: footerText[5] }}></Text>
            <Text dangerouslySetInnerHTML={{ __html: footerText[6] }}></Text>
            <Text dangerouslySetInnerHTML={{ __html: footerText[7] }}></Text>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default FooterText;
