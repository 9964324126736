import CloseSvg from "@/assets/svg/close-1.svg";
import SearchSvg from "@/assets/svg/search.svg";
import { useActions } from "@/hooks/useActions";
import useCompaniesListRequest from "@/hooks/useCompaniesListRequest";
import useGetCurrentView from "@/hooks/useGetCurrentView";
import { Flex, Icon, Input } from "@chakra-ui/react";
import { memo } from "react";
import { Controller, useForm } from "react-hook-form";
import SendIcon from "@/assets/svg/send-icon.svg";

const SearchCompanies = () => {
  const { setViewParams, setSearchCompanyValue } = useActions();
  const { currentParams, currentView } = useGetCurrentView();
  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      search: "",
    },
    values: {
      search: currentParams?.text,
    },
  });
  const { getCompanies } = useCompaniesListRequest();
  const searchValue = watch("search");

  const searchCompaniesHandler = (search) => {
    setViewParams({ key: currentView?.id, params: { text: search } });
    getCompanies({
      params: {
        ...currentParams,
        orderBy: currentParams?.orderBy?.value,
        limit: currentParams?.limit || 25,
        text: search || undefined,
      },
    });
  };

  const clearSearchHandler = () => {
    setViewParams({ key: currentView?.id, params: { text: undefined } });
    getCompanies({
      params: {
        ...currentParams,
        orderBy: currentParams?.orderBy?.value,
        text: undefined,
        limit: currentParams?.limit || 25,
      },
    });
    reset();
  };

  const onSubmitHandler = ({ search }) => {
    searchCompaniesHandler(search);
  };

  return (
    <Flex w="full" justify="center" mx={"12px"}>
      <Flex
        as={"form"}
        bg={"white"}
        w={"100%"}
        maxW={"583px"}
        h={"34px"}
        columnGap={"4px"}
        align={"center"}
        px={"6px"}
        pe={"12px"}
        borderRadius={"4px"}
        borderWidth={"1px"}
        borderColor={"borderGray"}
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Icon
          as={SearchSvg}
          color={"gray"}
          bgColor={"transparent"}
          _hover={{ bgColor: "transparent", color: "mainBlue" }}
          _active={{ bgColor: "transparent" }}
        />

        <Controller
          control={control}
          name="search"
          render={({ field }) => (
            <Input
              variant="unstyled"
              placeholder="Search..."
              fontFamily={"Roboto"}
              fontWeight={"500"}
              _placeholder={{
                pt: "4px",
              }}
              {...field}
            />
          )}
        />
        {searchValue ? (
          <Icon
            as={CloseSvg}
            cursor={"pointer"}
            color={"borderGray"}
            onClick={clearSearchHandler}
          />
        ) : null}
        <Icon
          as={SendIcon}
          color={"grey"}
          cursor={"pointer"}
          onClick={handleSubmit(onSubmitHandler)}
        />
      </Flex>
    </Flex>
  );
};

export default memo(SearchCompanies);
