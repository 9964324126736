export const menuItemsFooter = [
  {
    section: 'Research',
    items: [
      // { id: 1, text: "Features", pathName: "/", alt: "Features" },
      // { id: 2, text: "Pricing", pathName: "/", alt: "Pricing" },
      {
        id: 3,
        text: 'Research Companies',
        pathName: '/companies',
        alt: 'Research Companies',
      },

      {
        id: 5,
        text: 'Research Investors',
        pathName: '/investors',
        alt: 'Research Investors',
      },
      {
        id: 6,
        text: 'Research People',
        pathName: '/people',
        alt: 'Research People',
      },
    ],
  },
  // {
  //   section: 'Investors',
  //   items: [
  //     {
  //       id: 1,
  //       text: 'Investment Platform',
  //       pathName: '/',
  //       alt: 'Investment Platform',
  //     },
  //     {
  //       id: 2,
  //       text: 'View Available Funds',
  //       pathName: '/',
  //       alt: 'View Available Funds',
  //     },
  //   ],
  // },
  // {
  //   section: "Fund Managers",
  //   items: [
  //     {
  //       id: 1,
  //       text: "Fund Management Tools",
  //       pathName: "/",
  //       alt: "Fund Management Tools",
  //     },
  //   ],
  // },
  {
    section: 'aVenture',
    items: [
      // { id: 1, text: "Contact Us", pathName: "/", alt: "Contact Us" },
      {
        id: 2,
        text: 'Feature Requests',
        pathName: 'https://aventure.canny.io/feature-requests',
        alt: 'Feature Requests',
      },
      // { id: 3, text: "Sitemap", pathName: "/", alt: "Sitemap" },

      {
        id: 4,
        text: 'Careers',
        pathName: ' https://jobs.ashbyhq.com/aVenture',
        alt: 'Careers',
      },
      {
        id: 5,
        text: 'Report a Problem',
        alt: 'Report',
      },
      { id: 6, text: 'Blog', pathName: '/blog', alt: 'Blog' },
    ],
  },
];
