import { notificationsActions } from "@/store/notifications/notifications.slice";
import { axiosService } from "./apiService/axiosService";
import { loadingError } from "./error.service";
import { aventureApi } from "./apiService/aventureApi";

const notificationsApi = aventureApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.get("app/user/info-notification");
          dispatch(notificationsActions.setNotifications(data?.data));
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      providesTags: ["notifications"],
    }),

    getUnreadNotifications: builder.query({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.get(
            "app/user/unread-info-notification"
          );
          dispatch(notificationsActions.setUnreadNotifications(data?.data));
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      providesTags: ["notifications"],
    }),

    markReadNotification: builder.mutation({
      async queryFn(noti_id, { dispatch }) {
        try {
          const { data } = await axiosService.patch(
            `app/user/info-notification/${noti_id}`
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: ["notifications"],
    }),

    markReadAllNotification: builder.mutation({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.post(
            `app/user/info-notification/mark-all-read`
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: ["notifications"],
    }),
    deleteNotification: builder.mutation({
      async queryFn(noti_id, { dispatch }) {
        try {
          const { data } = await axiosService.delete(
            `app/user/info-notification/${noti_id}`
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: ["notifications"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
  useMarkReadNotificationMutation,
  useDeleteNotificationMutation,
  useMarkReadAllNotificationMutation,
} = notificationsApi;
export default notificationsApi;
