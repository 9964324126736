const getCurrentNavLink = () => (state) => state.content.currentNavLink;
const getProfile_Company_id = () => (state) =>
  state.content.current_Profile_Company_Id;
const getSearchResults = () => (state) => state.content.searchResults;
const getProfile_Fund_id = () => (state) =>
  state.content.current_Profile_Fund_Id;
const getCompanyDetailsTabIndex = () => (state) =>
  state.content.companyDetailsTabIndex;
const getSelectedCompanyForCompare = () => (state) =>
  state.content.companyForCompare;
const getSortedParams = () => (state) => state.content.sortedParams;
const getStateSortedParams = () => (state) => state.content.stateSortedParams;
const getGrowingFilters = () => (state) => state.content.growingFilters;
const getOpenCard = () => (state) => state.content.openCard;
const getIsLoading = () => (state) => state.content.isLoading;
const getInvestingFunds = () => (state) => state.content.investingFunds;
const getSelectedCardAnaliseFund = () => (state) =>
  state.content.selectedCardAnalise;
const getSelectedCardAnaliseFund2 = () => (state) =>
  state.content.selectedCardAnalise2;
const getDefaultCardAnaliseFund = () => (state) =>
  state.content.defaultCardAnalise;
const getOwnershipList = () => (state) => state.content.ownershipList;
const getOngoingInvestingFunds = () => (state) =>
  state.content.ongoingInvestingFunds;
const getInvestChart = () => (state) => state.content.investChart;
const getOneNewInCompany = () => (state) => state.content.oneNewInCompany;
const getWithdrawInvestingFunds = () => (state) =>
  state.content.withdrawInvestingFunds;
const getIsOpenFilterBlock = () => (state) => state.content.isOpenFilterBlock;
const getCurrentFilterList = () => (state) => state.content.currentFilterList;
const getTempUser = () => (state) => state.content.tempUser;

const contentSelectors = {
  getCurrentNavLink,
  getProfile_Company_id,
  getSearchResults,
  getProfile_Fund_id,
  getCompanyDetailsTabIndex,
  getSelectedCompanyForCompare,
  getSortedParams,
  getStateSortedParams,
  getGrowingFilters,
  getOpenCard,
  getIsLoading,
  getInvestingFunds,
  getSelectedCardAnaliseFund,
  getDefaultCardAnaliseFund,
  getSelectedCardAnaliseFund2,
  getOwnershipList,
  getOngoingInvestingFunds,
  getInvestChart,
  getWithdrawInvestingFunds,
  getOneNewInCompany,
  getIsOpenFilterBlock,
  getCurrentFilterList,
  getTempUser,
};

export default contentSelectors;
