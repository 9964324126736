import { Box, Tooltip } from '@chakra-ui/react';
import { memo, forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const CustomBoxTooltip = forwardRef(({ children, ...props }, ref) => {
  return (
    <Box as='span' ref={ref} {...props}>
      {children}
    </Box>
  );
});

const TooltipDefault = ({
  label,
  custom_styles,
  children,
  placement,
  hasArrow,
  isOpen,
}) => {
  return (
    <Tooltip
      {...{ hasArrow, label, placement, isOpen }}
      {...custom_styles}
      borderRadius={'4px'}
    >
      <CustomBoxTooltip>{children}</CustomBoxTooltip>
    </Tooltip>
  );
};

export default memo(TooltipDefault);
