import HideEyeIcon from "@/assets/svg/hide-eye.svg";
import { Flex, Input, Text } from "@chakra-ui/react";
import { useState } from "react";

const AuthInput = ({
  input,
  hasError,
  label,
  icon,
  labelBg,
  styles,
  iconHandler,
  iconLeftStyle,
}) => {
  const [inputType, setInputType] = useState(input?.type);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (value) => {
    if (value === "") {
      setIsFocused(false);
    }
  };

  const showPasswordHandler = () => {
    setInputType((prev) => (prev === "text" ? "password" : "text"));
  };

  const topPosition = (+parseFloat(input?.h) || 48) / 3.31;
  const labelFontSize = (+parseFloat(input?.h) || 48) / 3;

  return (
    <Flex pos={"relative"} alignItems={"center"} {...styles}>
      <Text
        pos={"absolute"}
        top={isFocused || input?.value ? "-6.5px" : `${topPosition}px`}
        left={isFocused || input?.value ? "16px" : icon ? "32px" : "16px"}
        bg={labelBg || "dashboardBackground"}
        px={isFocused || input?.value ? "4px" : "0"}
        color={
          input?.isDisabled
            ? "disabledBg"
            : hasError
            ? "red"
            : isFocused || input?.value
            ? "mainBlue"
            : "grey"
        }
        fontSize={isFocused || input?.value ? "12px" : `${labelFontSize}px`}
        fontWeight={"400"}
        lineHeight={"1.3"}
        zIndex={isFocused || input?.value ? "1" : 0}
        transition={"all .15s ease-in"}
      >
        {label}
      </Text>
      <Input
        variant={"unstyled"}
        borderColor={hasError ? "red" : "grey"}
        borderWidth={"1px"}
        borderRadius={"4px"}
        ps={icon ? "32px" : "16px"}
        pe={input?.type === "password" ? "36px" : "16px"}
        w={"100%"}
        h={"48px"}
        _focus={{
          borderWidth: "2px",
          borderColor: hasError ? "red" : "mainBlue",
        }}
        fontFamily={"Roboto"}
        autoComplete="off"
        _placeholder={{ color: hasError ? "red" : "grey" }}
        fontSize={"16px"}
        fontWeight={"400"}
        lineHeight={"1.3"}
        letterSpacing={"0.5px"}
        color={hasError ? "red" : "mainBlue"}
        {...input}
        type={inputType}
        onFocus={handleFocus}
        onBlur={() => handleBlur(input?.value)}
      />
      {icon ? (
        <Flex
          pos={"absolute"}
          left={"16px"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={iconHandler ? "pointer" : "auto"}
          onClick={iconHandler}
          {...iconLeftStyle}
        >
          {icon}
        </Flex>
      ) : null}
      {input?.type === "password" ? (
        <Flex
          pos={"absolute"}
          right={"16px"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={showPasswordHandler}
          onTouchStart={() => setInputType("text")}
          onTouchEnd={() => setInputType("password")}
        >
          <HideEyeIcon />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default AuthInput;
