import { RoslindaleText } from "@/components/ui/text/RoslindaleText";
import { r_400_16_130, r_400_20 } from "@/styles/fontStyles";
import { menuItemsFooter } from "@/utils/menuItemsFooter";
import { sentryFeedbackHandler } from "@/utils/sentryFeedback";
import { Box, Flex, List, ListItem, Text } from "@chakra-ui/react";
import Link from "next/link";

function FooterSections() {
  return (
    <Flex flexDirection={{ base: "column", md: "row" }} alignItems="flex-start">
      {menuItemsFooter.map((section, index) => (
        <Box
          key={`${section?.id}-${index}`}
          mr={index < menuItemsFooter.length - 1 ? { md: "40px" } : undefined}
          mb={{ base: "20px", md: "0" }}
        >
          <Text {...r_400_20} color={"footerSection"} mb="18px">
            {section.section}
          </Text>
          <List color={"white"} style={{ listStyleType: "none", padding: 0 }}>
            {section.items.map((item, itemIndex) => (
              <ListItem
                key={`${item?.id}-${itemIndex}`}
                style={{
                  margin: `${
                    itemIndex < section.items.length - 1
                      ? "5px 0 17px 0"
                      : "5px 0"
                  }`,
                }}
              >
                <Flex
                  onClick={(e) => {
                    if (item?.text === "Report a Problem") {
                      e.stopPropagation();
                      e.preventDefault();
                      sentryFeedbackHandler();
                    }
                  }}
                >
                  <Link
                    href={item.pathName ? item.pathName : ""}
                    target={
                      item?.pathName?.includes("http") ? "_blank" : "_self"
                    }
                  >
                    <RoslindaleText
                      customStyle={{
                        ...r_400_16_130,
                        textDecoration: "underline",
                      }}
                    >
                      {item.text}
                    </RoslindaleText>
                    {/* <Text as="u"> {item.text}</Text> */}
                  </Link>
                </Flex>
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Flex>
  );
}

export default FooterSections;
