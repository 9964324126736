const getNewCompaniesList = () => (state) =>
  state.newCompaniesList.companiesList;
const getSelectedCompanies = () => (state) =>
  state.newCompaniesList.selectedCompanies;
const getColumnsFields = () => (state) => state.newCompaniesList.columnsField;
const getIsLoadingCompanies = () => (state) =>
  state.newCompaniesList.isCompaniesLoading;
const getIsCompaniesCards = () => (state) =>
  state.newCompaniesList.isCompaniesCards;

const newCompaniesListSelectors = {
  getNewCompaniesList,
  getSelectedCompanies,
  getColumnsFields,
  getIsLoadingCompanies,
  getIsCompaniesCards,
};

export default newCompaniesListSelectors;
